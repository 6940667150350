import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { FormStep, IAutoQuestionFormData } from '@employer/app/components/jobs-automation/automation-auto-question/auto-questions.model'
import { AutoQuestionStore } from '@employer/app/components/jobs-automation/automation-auto-question/auto-questions.store'
import { AutoQuestionModalTabComponent } from '@employer/app/components/jobs-automation/automation-auto-question/partials/auto-question-modal-tab.component'
import { E11ButtonModule } from '@engineering11/ui-lib/e11-button'
import { E11ModalGlobalService } from '@engineering11/ui-lib/e11-modal'
import { E11TabComponent, E11TabsComponent, E11TabsModule } from '@engineering11/ui-lib/e11-tabs'
import { E11UtilModule } from '@engineering11/ui-lib/e11-ui-utils'
import { ViewportService } from '@engineering11/web-utilities'
import { LetDirective } from '@ngrx/component'
import { TranslateModule } from '@ngx-translate/core'
import { Subject } from 'rxjs'
import { takeUntil, tap } from 'rxjs/operators'
import { AutomatedQuestionInterviewFormComponent } from './automated-question-interview-form'
import { AutomatedQuestionMessagesFormComponent } from './automated-question-messages-form'
import { AutomatedQuestionTriggerFormComponent } from './automated-question-trigger/automated-question-trigger-form.component'

@Component({
  selector: 'automated-question-modal',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AutomatedQuestionTriggerFormComponent,
    AutomatedQuestionInterviewFormComponent,
    AutomatedQuestionMessagesFormComponent,
    E11ButtonModule,
    LetDirective,
    E11TabsModule,
    E11UtilModule,
    TranslateModule,
    AutoQuestionModalTabComponent,
  ],
  template: `
    <ng-container *ngrxLet="formData$ as formData">
      <ng-container *ngrxLet="completedSteps$ as completedSteps">
        <ng-template #content>
          <div class="e11-bg-skin-white e11-sticky e11--top-4 e11-z-10">
            <div class="e11-bg-skin-grey/20 e11-rounded-lg e11-p-4 e11-mb-4">
              <h3 class="e11-mb-2">
                {{ jobTitle }}
              </h3>
              <p class="e11-mb-0">
                {{ jobLocation }}
              </p>
            </div>
          </div>
          <e11-tabs #tabsComponent [tabsClassOverrides]="'e11-justify-between e11-mb-4'" (selectedTabChanged)="selectedTabChanged($event)">
            <e11-tab #triggerTab [title]="'Name & Trigger'" [tabId]="FormStep.Trigger">
              <ng-template e11Template="customHeader">
                <auto-question-modal-tab [completed]="completedSteps[FormStep.Trigger]" [isActive]="triggerTab.active" [title]="triggerTab.title">
                  {{ "Pick what event will trigger the sending of your candidate's questions." | translate }}
                </auto-question-modal-tab>
              </ng-template>
              <ng-template e11Template="tabContent">
                @defer {
                <automated-question-trigger #triggerFormComponent [formData]="formData"></automated-question-trigger>
                }
              </ng-template>
            </e11-tab>
            <e11-tab #questionTab [title]="'Question'" [tabId]="FormStep.Question" [disabled]="!completedSteps[FormStep.Trigger]">
              <ng-template e11Template="customHeader">
                <auto-question-modal-tab [completed]="completedSteps[FormStep.Question]" [isActive]="questionTab.active" [title]="questionTab.title">
                  {{ 'Write or select the question(s) to be sent to the candidate.' | translate }}
                </auto-question-modal-tab>
              </ng-template>
              <ng-template e11Template="tabContent">
                @defer {
                <automated-question-interview-form #questionsFormComponent [formData]="formData"></automated-question-interview-form>
                }
              </ng-template>
            </e11-tab>
            <e11-tab #messagesTab [tabId]="FormStep.Messages" [title]="'Messages'" [disabled]="!completedSteps[FormStep.Question]">
              <ng-template e11Template="customHeader">
                <auto-question-modal-tab [completed]="completedSteps[FormStep.Messages]" [isActive]="messagesTab.active" [title]="messagesTab.title">
                  {{ 'Customize the messages that inform candidates they have questions to answer.' | translate }}
                </auto-question-modal-tab>
              </ng-template>
              <ng-template e11Template="tabContent">
                @defer {
                <automated-question-messages-form #messagesFormComponent [formData]="formData"></automated-question-messages-form>
                }
              </ng-template>
            </e11-tab>
          </e11-tabs>
        </ng-template>

        <ng-template #footer>
          <div class="e11-flex e11-justify-end">
            <e11-button
              (click)="onSave(formData)"
              color="primary-accent"
              size="sm"
              [value]="selectedTabId === FormStep.Messages || !creationMode ? 'Save Questions' : 'Continue'"
            ></e11-button>
          </div>
        </ng-template>
      </ng-container>
    </ng-container>
  `,
  styles: ``,
})
export class AutomatedQuestionModalComponent implements OnInit, OnDestroy {
  @ViewChild('content') content!: TemplateRef<any>
  @ViewChild('footer') footer!: TemplateRef<any>
  @ViewChild('tabsComponent') tabsComponent!: E11TabsComponent
  @ViewChild('triggerFormComponent') triggerFormComponent!: AutomatedQuestionTriggerFormComponent
  @ViewChild('questionsFormComponent') questionsFormComponent!: AutomatedQuestionInterviewFormComponent
  @ViewChild('messagesFormComponent') messagesFormComponent!: AutomatedQuestionMessagesFormComponent
  @Input() jobDetail!: { jobTitle: string; locationAddress: { city?: string; state?: string; [key: string]: any } }

  @Output() onSavedChanges = new EventEmitter<void>()
  FormStep = FormStep
  creationMode = false
  selectedTabId: FormStep = FormStep.Trigger

  destroyed$: Subject<boolean> = new Subject<boolean>()
  isMobile: boolean = false

  formData$ = this.autoQuestionStore.formData$
  completedSteps$ = this.autoQuestionStore.completedSteps$.pipe(
    tap(completed => {
      this.handleStepAutoNavigation(completed)
    }),
    takeUntil(this.destroyed$)
  )

  constructor(
    private modalService: E11ModalGlobalService,
    private autoQuestionStore: AutoQuestionStore,
    private viewportService: ViewportService,
    private cdr: ChangeDetectorRef
  ) {
    this.resetCurrentAutomationOnClose()
  }

  ngOnInit(): void {
    this.viewportService.viewportSizeChanged$.pipe(takeUntil(this.destroyed$)).subscribe(data => {
      this.isMobile = data == 'sm' || data == 'xs' || data == 'md'
    })
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true)
    this.destroyed$.complete()
  }

  open(creationMode = true) {
    this.creationMode = creationMode

    this.modalService.openModal({
      body: this.content,
      title: 'Automated Questions',
      width: 'xl',
      bodyHeight: this.isMobile ? 'half' : 'large',
      showFooter: true,
      footer: this.footer,
    })
  }

  private resetCurrentAutomationOnClose() {
    this.modalService.modalClosed$.pipe(takeUntilDestroyed()).subscribe(() => {
      this.autoQuestionStore.setCurrentAutoQuestionFormId(undefined)
    })
  }

  private handleStepAutoNavigation(completed: Record<FormStep, boolean>) {
    if (!this.creationMode || !this.tabsComponent) {
      return
    }

    if (!completed[this.selectedTabId]) {
      return
    }
    // order of the tabs
    const tabs = [FormStep.Trigger, FormStep.Question, FormStep.Messages]
    const selectedIdIndex = tabs.findIndex(tab => tab === this.selectedTabId)
    if (selectedIdIndex === tabs.length - 1) {
      // if the last tab is completed, close the modal
      this.modalService.closeModal()
      return
    }

    this.tabsComponent.selectTabById(tabs[selectedIdIndex + 1])
    return
  }

  onSave(existingFormData: IAutoQuestionFormData | undefined) {
    this.cdr?.detectChanges() // TODO: use valueChanges in child components to update the state and avoid the need for this

    const triggerFormValue = this.triggerFormComponent?.validateAndReturnData?.()

    if (!existingFormData && !triggerFormValue) {
      return
    }

    if (!existingFormData && triggerFormValue) {
      this.autoQuestionStore.onAddAutoQuestion(triggerFormValue)
      this.onSavedChanges.emit()
      return
    }

    const questionsFormValue = this.questionsFormComponent?.validateAndReturnData?.()
    const messagesFormDValue = this.messagesFormComponent?.validateAndReturnData?.()

    if (!triggerFormValue && !questionsFormValue && !messagesFormDValue) {
      return
    }

    const payload: IAutoQuestionFormData = {
      ...existingFormData!,
      ...triggerFormValue,
      ...questionsFormValue,
      ...messagesFormDValue,
    }
    this.autoQuestionStore.onUpdateCurrentAutoQuestion(payload)
    this.onSavedChanges.emit()
  }

  selectedTabChanged(tab: E11TabComponent) {
    this.selectedTabId = tab.tabId as FormStep
    if (this.cdr) this.cdr.markForCheck()
  }

  get jobTitle(): string {
    return this.jobDetail?.jobTitle || ''
  }

  get jobLocation(): string {
    const city = this.jobDetail?.locationAddress?.city || ''
    const state = this.jobDetail?.locationAddress?.state || ''
    return `${city}${city && state ? ', ' : ''}${state}`
  }
}
