import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core'
import { SubscriptionManagementService } from '@candidate/app/services/subscription.management.service'
import { MAX_DURATION_COMPANY_VIDEO } from '@employer/app/app.constants'
import { CustomerService, ICustomer } from '@engineering11/customer-web'
import { FilesService } from '@engineering11/files-web'
import { IUploadedVideoResult, VIDEO_PLAYER_ACTIONS, VideoPlayerResource } from '@engineering11/multimedia-web'
import { E11NotificationMessage, E11NotificationsService } from '@engineering11/ui-lib/e11-notifications'
import { E11SideOverService } from '@engineering11/ui-lib/e11-side-over'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { EmployerService, IEmployer, IEmployerVideoContent, MIN_DURATION_VIDEO, TokenStorage, VideoFileUploadData } from 'shared-lib'
import { EmployerStore } from '../../employer.store'
import { E11ErrorHandlerService } from '@engineering11/web-api-error'

@Component({
  selector: 'customer-manage-company-logo',
  templateUrl: './manage-company-logo.component.html',
  styleUrls: ['./manage-company-logo.component.scss'],
})
export class ManageCompanyLogoComponent implements OnInit, OnDestroy {
  logoInfo =
    "This logo is used many places including internal and external Job Posts, your Careers Page, and Candidate's Search Results just to name a few. Please make sure it is a .png or .jpg file no larger than 5GB."

  @ViewChild('addUploadedVideo') addUploadedVideo!: TemplateRef<any>

  customer?: ICustomer
  employer?: IEmployer

  employer$ = this.employerStore.employer$
  destroy$: Subject<boolean> = new Subject<boolean>()

  loading: boolean = false
  subs: SubscriptionManagementService = new SubscriptionManagementService()

  minDurationOfVideos = MIN_DURATION_VIDEO
  maxDurationOfVideos = MAX_DURATION_COMPANY_VIDEO

  companyVideo: IEmployerVideoContent | null = null
  showVideoModal: boolean = false
  currentPlayerAction: VIDEO_PLAYER_ACTIONS = VIDEO_PLAYER_ACTIONS.IDLE
  uploadedVideo: VideoFileUploadData | undefined = undefined

  videoPlayerResource?: VideoPlayerResource

  constructor(
    private employerStore: EmployerStore,
    private customerService: CustomerService,
    private tokenStorage: TokenStorage,
    private filesService: FilesService,
    private sideOverService: E11SideOverService,
    private employerService: EmployerService,
    private notificationsService: E11NotificationsService,
    private errorHandler: E11ErrorHandlerService
  ) {}

  ngOnInit(): void {
    const userLocalStorage = this.tokenStorage.getItem('user')

    this.employer$.pipe(takeUntil(this.destroy$)).subscribe(employer => {
      this.employer = Object.assign({}, employer)

      if (!employer) return

      this.companyVideo = employer.companyVideo!
    })
    if (userLocalStorage) {
      this.subs.next = this.customerService.get(userLocalStorage.customerKey).subscribe(data => {
        this.customer = data
      })
    }
  }

  ngOnDestroy(): void {
    this.subs.stop()
  }

  async removeCompanyVideo(confirmation: boolean) {
    if (!confirmation || !this.employer || !this.companyVideo || !this.companyVideo.video?.url) {
      return
    }

    this.closeSideOver()

    await this.filesService.deleteFromUrl(this.companyVideo.video.url, { throwOnError: false })

    this.companyVideo = null

    this.employer.companyVideo = this.companyVideo

    await this.employerService.set(this.employer)

    const notification: E11NotificationMessage = {
      title: 'Success',
      message: 'Company video deleted successfully',
      type: 'success',
      autoClose: true,
      dismissOnRouteChange: true,
    }

    this.notificationsService.popNotificationMessage(notification)
  }

  closeSideOver() {
    this.showVideoModal = false
    this.sideOverService.overlayToggle(false)
  }

  viewVideo(content: TemplateRef<any>) {
    this.filesService.getVideoFallbackURL(this.companyVideo!.video).then(fallbackUrl => {
      this.videoPlayerResource = {
        video: this.companyVideo?.video.videoId!,
        fallbackVideoUrl: fallbackUrl,
      }
    })
    this.showVideoModal = true
    this.sideOverService.overlayToggle(true, content, { size: 'md' })
  }

  videoFileUpload(videoData: VideoFileUploadData) {
    this.uploadedVideo = videoData

    this.sideOverService.overlayToggle(true, this.addUploadedVideo, { size: 'md' })
  }

  async saveCompanyVideo(uploadedVideoResult: IUploadedVideoResult) {
    if (!this.employer) {
      return
    }

    const companyVideo = {
      thumb: uploadedVideoResult.thumb,
      video: uploadedVideoResult.video,
      videoLength: uploadedVideoResult.videoLength!,
      name: uploadedVideoResult.name!,
    }

    if (this.companyVideo?.video?.url) {
      await this.filesService.deleteFromUrl(this.companyVideo.video.url, { throwOnError: false })
    }

    this.employer.companyVideo = companyVideo

    await this.employerService.set(this.employer)

    this.companyVideo = companyVideo

    const notification: E11NotificationMessage = {
      title: 'Success',
      message: 'Company video updated successfully',
      type: 'success',
      autoClose: true,
      dismissOnRouteChange: true,
    }

    this.notificationsService.popNotificationMessage(notification)
  }
}
