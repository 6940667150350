<e11-panel>
  <div class="e11-flex e11-flex-wrap md:e11-flex-nowrap e11-gap-4 md:e11-gap-20 e11-mb-8">
    <div class="e11-w-full md:e11-w-1/2.5">
      <sdk-customer-logo [logoInfo]="logoInfo"></sdk-customer-logo>
    </div>
    <div class="e11-block md:e11-hidden e11-w-full">
      <e11-divider [margin]="1" [width]="100"></e11-divider>
    </div>
    <div class="e11-w-full md:e11-w-1/2.5">
      <e11-loader-dots
        *ngIf="loading || !customer || !employer"
        [color]="'e11-bg-skin-primary-accent'"
        [label]="'Loading video...' | translate"
      ></e11-loader-dots>
      <div *ngIf="!loading && customer && employer">
        <h3>{{ 'Company Video' | translate }}</h3>
        <p class="e11-m-0 e11-text-sm">{{ 'Your company\'s flagship "About the Company" video.' | translate }}</p>
        <p class="e11-text-sm">
          {{
            'This video will be shown on the “Company” tab of your Cnect Careers Page, and on job posts that do not have a job-specific video added.'
              | translate
          }}
        </p>

        <div *ngIf="companyVideo && companyVideo.video?.url" class="e11-mb-4">
          <e11-card>
            <div
              (click)="viewVideo(videoDetail)"
              class="image-container e11-relative e11-flex e11-items-center e11-justify-center e11-cursor-pointer"
            >
              <span class="icon material-icons e11-absolute e11-opacity-50"> play_circle_outline </span>
              <img [alt]="companyVideo.name" [src]="companyVideo.thumb?.url" class="e11-mb-2" />
            </div>

            <h4 class="e11-mb-1 e11-truncate">{{ companyVideo.name }}</h4>
            <div class="e11-w-full e11-flex e11-items-center">
              <div class="">
                <p class="e11-mb-0 e11-bg-skin-app-bg-dark e11-text-skin-extra-light e11-py-1 e11-px-2 e11-rounded e11-text-sm">
                  {{ companyVideo.videoLength }}
                </p>
              </div>
              <div class="e11-grow e11-flex e11-items-center e11-justify-end">
                <e11-button
                  (click)="confirmation.open()"
                  [color]="'secondary'"
                  [ghost]="true"
                  [iconPosition]="'left'"
                  [iconSize]="'md-18'"
                  [icon]="'delete'"
                  [titleText]="'Delete this video.' | translate"
                  value="{{ 'Delete' | translate }}"
                ></e11-button>
              </div>
            </div>
          </e11-card>
        </div>

        <div *ngIf="!companyVideo || !companyVideo.video" class="e11-mb-4">
          <e11-card (click)="videoRecordingComponent.open(); $event.stopPropagation()" [borderDashed]="true" [status]="true">
            <div class="e11-card-video-ph-image">
              <span class="icon material-icons e11-text-skin-white"> person_pin </span>
            </div>

            <div class="e11-w-full e11-flex e11-items-center">
              <div class="">
                <p class="e11-mb-0 e11-bg-white e11-text-skin-light e11-py-1 e11-px-2 e11-rounded e11-text-sm">00:00</p>
              </div>
              <div class="e11-grow e11-flex e11-items-center e11-justify-end">
                <e11-button
                  (click)="videoRecordingComponent.open()"
                  [color]="'primary-accent'"
                  [ghost]="true"
                  [iconPosition]="'left'"
                  [iconSize]="'md-14'"
                  [icon]="'radio_button_checked'"
                  [titleText]="'Record a video.' | translate"
                  value="{{ 'Record' | translate }}"
                ></e11-button>
              </div>
            </div>
          </e11-card>
        </div>

        <div class="e11-text-left e11-mb-2">
          <app-video-file-upload
            (newVideoData)="videoFileUpload($event)"
            [buttonCopy]="'Upload an existing video' | translate"
            [buttonOnly]="true"
            [maxDuration]="maxDurationOfVideos"
            [minDuration]="minDurationOfVideos"
            [showButtonIcon]="true"
          ></app-video-file-upload>
        </div>
        <div>
          <p class="e11-mb-0 e11-text-sm">{{ 'If you have an existing video you would like to use, upload it here!' | translate }}</p>
          <p class="e11-text-xs">
            {{ (' (Video may be up to ' | translate) + maxDurationOfVideos + (' seconds and in .mp4 or .webm format.)' | translate) }}
          </p>
        </div>
      </div>
    </div>
  </div>
</e11-panel>

<e11-confirmation
  #confirmation
  (answer)="removeCompanyVideo($event)"
  [backdropStyle]="'dark'"
  cancelText="{{ 'Cancel' | translate }}"
  confirmText="{{ 'Delete' | translate }}"
  confirmTitle="{{ 'Deleting A Video' | translate }}"
>
  <p>{{ 'Are you sure you want to permanently delete this video?' | translate }}</p>
  <p>{{ 'This cannot be undone.' | translate }}</p>
</e11-confirmation>

<compact-video-recording
  #videoRecordingComponent
  (uploadCompleted)="saveCompanyVideo($event)"
  [action]="currentPlayerAction"
  [enableTeleprompterControl]="true"
  [filename]="'company_video'"
  [makePublic]="true"
  [maximumRecordSec]="maxDurationOfVideos"
  [showIntroductionCarousel]="false"
>
</compact-video-recording>

<ng-template #videoDetail>
  <ui-video-detail
    (closeFired)="closeSideOver()"
    (deleteFired)="removeCompanyVideo($event)"
    *ngIf="companyVideo"
    [showContent]="showVideoModal"
    [video]="companyVideo"
    [videoPlayerResource]="videoPlayerResource"
    title="Company Video"
  ></ui-video-detail>
</ng-template>

<ng-template #addUploadedVideo>
  <video-upload-file
    (videoUploaded)="saveCompanyVideo($event)"
    *ngIf="uploadedVideo"
    [filename]="'company_video'"
    [makePublic]="true"
    [title]="'Add Company Video'"
    [videoFile]="uploadedVideo.videoFile"
    [videoRecordedTimeSet]="uploadedVideo.durationStr"
  ></video-upload-file>
</ng-template>
